<template>
    <div class="modal-basic">
        <div class="explain-container">
            <div class="title">
                <h3>밥포인트</h3>
                <p>소개팅해준 주선자에게 감사 의미로 밥을 사는 문화를 바닐라브릿지에 옮겨왔어요!</p>
            </div>

            <div class="explain">
                <p>주선한 소개팅이 실제 만남까지 성사되면,</p>
                <p><span>소개팅 1건당 10,000 밥포인트</span> 지급!</p>
                <p>(1 밥포인트 = 1원, 현금 환전 가능)</p>
            </div>
        </div>

        <hr class="hor-divider" />
        <div class="buttons-basic">
            <div class="flex-row">
                <button @click="onClickButton">확인</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalRicePoint',
    methods: {
        onClickButton() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    color: $grey-09;
    padding: 0;

    .explain-container {
        padding: 16px 16px 0 16px;
    }

    .title {
        margin-bottom: 16px;
        h3 {
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;
            line-height: normal;
        }
    }

    .explain {
        background-color: $grey-01;
        padding: 12px;
        border-radius: 10px;
        margin-bottom: 20px;

        p {
            font-size: 13px;
            text-align: center;
            margin-bottom: 4px;

            span {
                color: black;
                @include f-bold;
            }
        }
    }
    button {
        color: $purple-primary;
    }
}
</style>
